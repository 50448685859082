import { useTranslation, useI18next } from 'gatsby-plugin-react-i18next';
import {routes} from "../data/routes";

export const checkMetaTitle = (location) => {
	const { t } = useTranslation();
	const { language } = useI18next();

	const languagePattern = new RegExp(`^/${language}`);
	const normalizedLocation = location.replace(languagePattern, '');

	const defaultMetaTitle = t('defaultMetaTitle');

	switch (normalizedLocation) {
		case routes.index:
			return t("meta_title_home");
		case routes.login:
			return `${defaultMetaTitle} - ${t("meta_title_login")}`;
		case routes.diary:
			return `${defaultMetaTitle} - ${t("meta_title_diary")}`;
		case routes.feedback:
			return `${defaultMetaTitle} - ${t("meta_title_feedback")}`;
		case routes.resources:
			return `${defaultMetaTitle} - ${t("meta_title_resources")}`;
		case routes.guide:
			return `${defaultMetaTitle} - ${t("meta_title_guide")}`;
		case routes.packages:
			return `${defaultMetaTitle} - ${t("meta_title_packages")}`;
		default:
			return defaultMetaTitle;
	}
}