import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import {Helmet} from 'react-helmet';

const Head = ({title}) => {
	const { t } = useTranslation();

	return (
		<>
			<Helmet
				defaultTitle={t("default_site_title")}
				title={title}
			/>
		</>
	)
}

export default Head;
