import React, { useContext } from "react";
import { Link, navigate } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";

import { authContext } from "../contexts/authContext";

const MobileMenu = (props) => {
	const auth = useContext(authContext);
	const linkClassName = "m-0 cursor-pointer hover:text-green mt-5";
	const { t } = useTranslation();

	function handleLogoutBtn(event) {
		event.preventDefault();
		navigate(`/`);
		setTimeout(() => auth.logout(() => {}), 1000);
	}

	function isLinkActive(path) {
		return path === props.pathname;
	}

	// Prevent mobile menu from closing when interacting with the dropdown
	const handleDropdownClick = (event) => {
		event.stopPropagation();
	};

	return (
		<>
			<div
				className="bg-black bg-opacity-10 fixed left-0 top-0 h-full w-full m-0 z-20"
				onKeyDown={props.toggleOpened}
				onClick={props.toggleOpened}
				role="button"
				tabIndex={0}
			>
				<div
					className="bg-white h-full w-9/12 flex flex-col text-xl items-center"
					role="button"
					tabIndex={0}
					onClick={(e) => e.stopPropagation()} // Prevent closing on inner container clicks
					onKeyDown={(e) => e.preventDefault()}
				>
					<Link
						to="/"
						className={linkClassName + (isLinkActive("/") ? " text-green" : "")}
					>
						{t("menu_support_center")}
					</Link>
					<Link
						to="/app/guide"
						className={
							linkClassName +
							(isLinkActive("/app/guide") ? " text-green" : "")
						}
					>
						{t("menu_guide")}
					</Link>
					<Link
						to="/app/feedback"
						className={
							linkClassName +
							(isLinkActive("/app/feedback") ? " text-green" : "")
						}
					>
						{t("menu_send_message")}
					</Link>
					<Link
						to="/app/diary"
						className={
							linkClassName +
							(isLinkActive("/app/diary") ? " text-green" : "")
						}
					>
						{t("menu_diary")}
					</Link>
					<Link
						to="/app/resources"
						className={
							linkClassName +
							(isLinkActive("/app/resources") ? " text-green" : "")
						}
					>
						{t("menu_download")}
					</Link>
					{auth.isLoggedIn ? (
						<Link to="/" className={linkClassName} onClick={handleLogoutBtn}>
							{t("menu_logout")}
						</Link>
					) : (
						<Link
							to="/login"
							className={
								linkClassName +
								(isLinkActive("/login") ? " text-green" : "")
							}
						>
							{t("menu_login")}
						</Link>
					)}

					{/* Render additional content, such as language selector */}
					<div className="mt-5" onClick={handleDropdownClick}>
						{/* Stop click event from closing the menu */}
						{props.children}
					</div>
				</div>
			</div>
		</>
	);
};

export default MobileMenu;
