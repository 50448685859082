import React from "react";

import Header from "../Header";
import Footer from "../Footer";
import Head from "../SEO/Head";

import WithProvider from "../WithProvider";

import {checkMetaTitle} from "../../utils/checkMetaTitle";

// Global styles and component-specific styles.
import "./global.css";

const Layout = ({ children, location }) => (
    // Root container flex setup
    <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
        <Head title={checkMetaTitle(location.pathname)} />
        <WithProvider>
            <Header location={location} />
            <main
                className="bg-white md:bg-background-page h-full md:py-5 md:px-4 m-0 font-sans text-primary flex-1"
            >
                {children}
            </main>
            <Footer />
        </WithProvider>
    </div>
);

export default Layout
