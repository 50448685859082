import React, {useContext, useState} from "react"

import {navigate} from "gatsby"

import { Link, useI18next, useTranslation } from 'gatsby-plugin-react-i18next';

import { Burger, Select } from '@mantine/core'; // Import the Select component

import {authContext} from "../contexts/authContext";

import MobileMenu from "./MobileMenu";

const Header = (props) => {
	const auth = useContext(authContext);
	const linkClassName = "m-0 cursor-pointer hover:text-green";
	const { t } = useTranslation();
	const [opened, setOpened] = useState(false);
	const title = opened ? 'Close navigation' : 'Open navigation';
	
	const {language, languages, changeLanguage} = useI18next();
	const logo = require(`../images/${language}-logo.png`).default;
	
	function toggleOpened() {
		setOpened((o) => !o)
	}

	function handleLogoutBtn(event) {
		event.preventDefault();
		navigate(`/`)
		setTimeout(() => auth.logout(() => {
		}), 1000)
	}

	function isLinkActive(path) {
		return path === props.location?.pathname
	}

	return (
		<header>
			<div
				className={"max-w-5xl m-auto flex items-center md:items-start lg:items-center md:flex-col justify-between text-base leading-5 font-sans text-primary px-3 lg:flex-row md:text-sm lg:text-base"}>
				<a href={t("logo_link_url")} className="w-286">
					<img src={logo} alt="Logo" className="w-full" />
				</a>
				<div className={"hidden md:flex  justify-between items-center w-full m-0 lg:ml-10 md:mb-3 lg:mb-0"}>
					<Link to={'/'}
						  className={linkClassName + (isLinkActive('/') ? ' text-green' : '')}>{t('menu_support_center')}</Link>
					<Link to={'/app/guide'}
						  className={linkClassName + (isLinkActive('/app/guide') || isLinkActive('/app/guide/') ? ' text-green' : '')}>{t('menu_guide')}</Link>
					<Link to={'/app/feedback'}
						  className={linkClassName + (isLinkActive('/app/feedback') || isLinkActive('/app/feedback/') ? ' text-green' : '')}>{t('menu_send_message')}</Link>
					<Link to={'/app/diary'}
						  className={linkClassName + (isLinkActive('/app/diary') || isLinkActive('/app/diary/') ? ' text-green' : '')}>{t('menu_diary')}</Link>
					<Link to={'/app/resources'}
						  className={linkClassName + (isLinkActive('/app/resources') || isLinkActive('/app/resources/') ? ' text-green' : '')}>{t('menu_download')}</Link>
					{auth.isLoggedIn
						? <Link to={'/'} className={linkClassName} onClick={handleLogoutBtn}>{t('menu_logout')}</Link>
						: <Link to={'/login/'}
								className={linkClassName + (isLinkActive('/login/') || isLinkActive('/login') ? ' text-green' : '')}>{t('menu_login')}</Link>}

					<div className="relative" style={{ height: '60px' }}>
						<Select
							value={language}
							onChange={(value) => changeLanguage(value)}
							data={languages.map((lng) => ({ value: lng, label: lng.toUpperCase() }))}
							classNames={{
							root: 'ml-1.25 inline-block align-middle',
							input: 'w-16 h-10 p-0 text-center',
							}}
						/>
					</div>
				</div>

				<div className={"block md:hidden m-0"}>
					<Burger opened={opened} onClick={toggleOpened} title={title} />
					{opened ? (
						<MobileMenu pathname={props.location.pathname} toggleOpened={toggleOpened}>
						{/* Language Selector for Mobile */}
						<Select
							value={language}
							onChange={(value) => changeLanguage(value)}
							data={languages.map((lng) => ({ value: lng, label: lng.toUpperCase() }))}
							classNames={{
							root: 'ml-1.25 inline-block align-middle',
							input: 'w-16 h-10 p-0 text-center',
							}}
						/>
						</MobileMenu>
					) : null}
					</div>
			</div>
		</header>
	)
}

export default Header
