import React from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";

const Footer = () => {
    const { t } = useTranslation();
    const currentYear = new Date().getFullYear();

    return (
        <footer className="w-full font-sans text-sm text-primary md:my-3 lg:my-4">
            <div className="container mx-auto px-2 lg:px-11 h-full xl:max-w-1100">
                <div className="h-full w-full max-w-5xl flex items-center">
                    <p className="text-sm font-normal">
                        {t("footer_text", { year: currentYear })}
                    </p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
